import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router'
import {baseURL} from './config'

let isShowMessage = true

export function request (method, url, parameter, isToken, isJson) { // , requestData
  let data = {}
  let params = {}
  let headers= {}
  if (isToken) {
    headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token_shop'),
    }
  } else {
    if (isJson) {
      headers = {
        'Content-Type': 'application/json',
      }
    } else {
      headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic c2hvcDpzaG9w',
      }
    }
  }
  if (method === 'GET' || method === 'DELETE') {
    params = parameter
  } else {
    data = parameter
  }
  return axios({
    method: method,
    baseURL: baseURL,
    url: url,
    timeout: 30000,
    headers: headers,
    params: params,
    data: data,
  }).then((response) => {
    const res = response.data
    if (response.status === 200) {
      if (url === '/oauth/token') {
        if (res.error_description) {
          Message({
            message: response.data.error_description,
            type: 'error',
            duration: 2 * 1000,
            showClose: true
          })
        } else {
          return res
        }
        return false
      }
      if (res.code === 0 || res.code === 500109 || res.code === 500113) {
        return res
      } else {
        if (isShowMessage) {
          Message({
            message: res.message || 'Error',
            type: 'error',
            duration: 2 * 1000,
            showClose: true
          })
          isShowMessage = false
          setTimeout(function () {
            isShowMessage = true
          }, 5000)
        }
        return res
      }
    } else if (response.status === 401) {
      localStorage.removeItem('access_token_shop')
      router.push('/login')
      if (isShowMessage) {
        if (url === '/oauth/token') {
          Message({
            message: response.data.error_description,
            type: 'error',
            duration: 2 * 1000,
            showClose: true
          })
        } else {
          Message({
            message: '登录已过期，请重新登录',
            type: 'error',
            duration: 2 * 1000,
            showClose: true
          })
        }
        isShowMessage = false
        setTimeout(function () {
          isShowMessage = true
        }, 5000)
      }
    } else {
      if (isShowMessage) {
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 2 * 1000,
          showClose: true
        })
        isShowMessage = false
        setTimeout(function () {
          isShowMessage = true
        }, 5000)
      }
    }
  }).catch((error) => {
    if (error.response.status === 401) {
      if (url === '/oauth/token') {
        let message = error.response.data.error_description
        Message({
          message: message,
          type: 'error',
          duration: 2 * 1000,
          showClose: true
        })
      } else {
        localStorage.removeItem('access_token_shop');
        router.push('/login')
        Message({
          message: '登录已过期，请重新登录',
          type: 'error',
          duration: 2 * 1000,
          showClose: true
        })
      }
    }

    return error
  })
}
