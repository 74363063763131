// export const baseURL = 'https://unionpay.ylzcf.com/scmshop';
// export const uploadURL = 'https://unionpay.ylzcf.com/scmshop/common/fileUpload';
// export const uploadURLSeal = 'https://unionpay.ylzcf.com/scmshop/common/fileUpload/seal';
export const baseURL = 'http://101.201.57.236:8088/scmshop'
export const uploadURL = 'http://101.201.57.236:8088/scmshop/common/fileUpload'
export const uploadURLSeal = 'http://101.201.57.236:8088/scmshop/common/fileUpload/seal';

export const imageURL = 'https://img.yc8879.com/scm-shop/';
export const imagetools_cors_hosts = 'minio.paas.naodaida.com';

export const consumerPhone = '400-091-8879';

export const payReturnUrl = 'https://unionpay.ylzcf.com/scmshop-web/#/shop/car/success';
